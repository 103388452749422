import React from "react";
import { useIsMounted } from "repoV2/utils/common/render/mount";
import { DEFAULT_PROFILE_PIC_SRC } from "repoV2/utils/common/assets/assets";
import { SECTION_IDS } from "@Utils";
import { ReadMoreWrapper } from "@Modules/common/ReadMoreWrapper";
import { FollowerCountIcons } from "@Modules/hostPage/FollowerCountIcons";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import { IAbout } from "./About";
import style from "./About.module.scss";

export const About = ({
    postAnalyticalEvent,
    hostData,
    // TODO: move all above props to aboutSectionProps in pages/host/index.tsx

    // props defined in aboutSectionProps in pages/host/index.tsx
    preview,
    hideSocialIcons,
    socialLinks,
    shortDescription,
    name,
    description,
    expandHostDesc,
}: IAbout.IProps): JSX.Element => {
    const isMounted = useIsMounted();
    return (
        <div className={style.aboutContainer}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a id={SECTION_IDS.about} className={style.anchorAbout} />
            <div className={style.imageContainer}>
                {(hostData?.profile_pic && (
                    <ExlyImage
                        fetchWidth={750}
                        src={hostData.profile_pic}
                        alt="profileimg"
                        className={style.profileImg}
                        loading="lazy"
                        // layout="fill"
                    />
                )) ||
                    (preview && (
                        <div className={style.previewProfile}>
                            {"< Your profile pic will be displayed here > "}
                        </div>
                    )) || (
                        <img
                            src={DEFAULT_PROFILE_PIC_SRC}
                            alt="profileimg"
                            className={style.profileImg}
                            loading="lazy"
                        />
                    )}
            </div>
            <div className={style.aboutData}>
                <div className={style.aboutHeading}>
                    <span className={style.about}>About</span>
                    {name ? <span className={style.name}>{name}</span> : null}
                </div>
                {shortDescription ? (
                    <div className={style.shortDesc}>
                        {shortDescription.slice(0, 200)}
                    </div>
                ) : null}
                <div className={style.desc}>
                    {description ? (
                        <>
                            {expandHostDesc ? (
                                <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            ) : (
                                <ReadMoreWrapper
                                    id="yoga_aboutSection_description"
                                    collapsedHeight={140}
                                    onlyExpand
                                    clickCallback={() => {
                                        if (!preview) {
                                            postAnalyticalEvent({
                                                event_name: "moreDesc",
                                                action: "Tap",
                                                ui_component: "Text Button",
                                                identifier: "Read more...",
                                            });
                                        }
                                    }}
                                >
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </ReadMoreWrapper>
                            )}
                        </>
                    ) : null}
                </div>
                {isMounted && (
                    <FollowerCountIcons
                        socialLinks={socialLinks}
                        hideSocialIcons={hideSocialIcons}
                    />
                )}
            </div>
        </div>
    );
};
