import React from "react";
import { Navbar } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import { AwardsList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/AwardsList";
import { BrandsList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/BrandsList";
import { MediaList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/MediaList";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import { FooterSection } from "repoV2/features/CreatorPages/modules/FooterSection";
import { MainPage } from "../components/mainPage";
import { About } from "../components/about";
import { BrowseSection } from "../components/browseSection";
import { Testimonial } from "../components/testimonials";
import { Portfolio } from "../components/portfolio";
import styles from "./modern.module.scss";
import { TeamSection } from "../components/TeamSection";
import { CustomCodeSection } from "../components/CustomCodeSection/CustomCodeSection";

const Modern = ({
    hostData,
    handlePreviewPopup,
    postAnalyticalEvent,
    preview,

    // common to be used by sub-components in TemplateRoots
    navbarProps,
    aboutSectionProps,
    eventSectionsProps,
    testimonialsSectionProps,
    gallerySectionProps,
    teamSectionProps,
    footerSectionProps,
}: ITemplate.IProps): JSX.Element => {
    return (
        <div className={styles.root}>
            <Navbar {...navbarProps} />
            <MainPage
                onGetInTouchClick={aboutSectionProps.onGetInTouchClick}
                getInTouchCtaText={aboutSectionProps.getInTouchCtaText}
                preview={preview}
                handlePreviewPopup={handlePreviewPopup}
                hostData={hostData}
            />
            <About
                postAnalyticalEvent={postAnalyticalEvent}
                hostData={hostData}
                {...aboutSectionProps}
            />
            <CustomCodeSection
                position={CUSTOM_CODES_POSITION.MAIN_CONTENT_START}
            />
            <AwardsList titleClass={`${styles.sectionTitle}`} />
            <BrowseSection {...eventSectionsProps} />
            <BrandsList titleClass={`${styles.sectionTitle}`} />
            <TeamSection {...teamSectionProps} />
            <Testimonial {...testimonialsSectionProps} />
            <MediaList titleClass={`${styles.sectionTitle}`} />
            <Portfolio
                {...gallerySectionProps}
                preview={preview}
                handlePreviewPopup={handlePreviewPopup}
            />
            <CustomCodeSection
                position={CUSTOM_CODES_POSITION.MAIN_CONTENT_END}
            />
            <FooterSection {...footerSectionProps} />
        </div>
    );
};

export default Modern;
