import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { BiChevronLeft } from "@react-icons/all-files/bi/BiChevronLeft";
import { BiChevronRight } from "@react-icons/all-files/bi/BiChevronRight";
import { classNames } from "repoV2/utils/common/render/classNames";
import {
    GALLERY_FILTERS,
    GALLERY_TABS,
    isEmpty,
    getItemCountFromResponsive,
    SECTION_IDS,
} from "@Utils";
import useMediaQuery from "@restart/hooks/useMediaQuery";
import { CarouselCustomDot } from "@Modules/common";
import { IPortfolio } from "./Portfolio";
import { PortfolioCard } from "../portfolioCard";
import style from "./Portfolio.module.scss";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1301 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1300, min: 901 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 900, min: 0 },
        items: 1,
    },
};

const ButtonGroup = ({
    next,
    previous,
    carouselState: { currentSlide },
}: any): JSX.Element => (
    <div className="carousel-button-group">
        <div
            className={`${style.leftButton} ${
                currentSlide === 0 ? "disable" : ""
            }`}
            onClick={previous}
        >
            <BiChevronLeft />
        </div>
        <div onClick={next} className={style.rightButton}>
            <BiChevronRight />
        </div>
    </div>
);

export const Portfolio = ({
    preview,
    handlePreviewPopup,
    title,
    galleryData,
    onGalleryItemClick,
    pauseGalleryAutoplay,
}: IPortfolio.IProps): JSX.Element | null => {
    const isDesktopOrLaptop: boolean = useMediaQuery(
        "(min-device-width: 769px)"
    );
    const isLargeDesktop: boolean = useMediaQuery("(min-device-width: 1070px)");

    const hasImages = galleryData.some(g =>
        ["photo", "image"].includes(g.type)
    );
    const hasVideos = galleryData.some(g => g.type === "video");

    const SHOW_TAB = {
        0: hasImages || hasVideos,
        1: hasImages,
        2: hasVideos,
    };

    const [galleryFilter, setGalleryFilter] = useState(GALLERY_TABS.ALL.key);

    useEffect(() => {
        // To keep galleryFilter declaratively linked to galleryData if a
        // state change would make the galleryFilter have an invalid valid
        if (!hasImages || !hasVideos) {
            setGalleryFilter(GALLERY_TABS.ALL.key);
        }
    }, [hasImages, hasVideos]);

    const data = galleryData.filter(
        GALLERY_FILTERS[galleryFilter as keyof typeof GALLERY_FILTERS]
    );

    return (
        (!isEmpty(data) && (
            <div className={style.portfolioContainer}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a id={SECTION_IDS.gallery} className={style.anchorGallery} />
                <div className={style.portfolioTitle}>{title}</div>
                {hasImages && hasVideos && (
                    <div className={style.portfolioHeading}>
                        {Object.values(GALLERY_TABS)
                            .filter(
                                item =>
                                    SHOW_TAB[item.key as keyof typeof SHOW_TAB]
                            )
                            .map(item => (
                                <span
                                    key={item.key}
                                    className={classNames(
                                        style.galleryItem,
                                        item.key === galleryFilter &&
                                            style.selectedItem
                                    )}
                                    onClick={() => setGalleryFilter(item.key)}
                                >
                                    {item.label}
                                </span>
                            ))}
                    </div>
                )}
                <div className={style.portfolio}>
                    <Carousel
                        responsive={responsive}
                        infinite
                        renderButtonGroupOutside
                        autoPlay={!pauseGalleryAutoplay}
                        autoPlaySpeed={5000}
                        arrows={false}
                        {...(isDesktopOrLaptop
                            ? {
                                  customButtonGroup:
                                      data.length >
                                      getItemCountFromResponsive(responsive) ? (
                                          <ButtonGroup />
                                      ) : null,
                              }
                            : {
                                  showDots: true,
                                  customDot: (
                                      <CarouselCustomDot
                                          design="dot"
                                          applyTheme
                                      />
                                  ),
                              })}
                    >
                        {data &&
                            data.map(item => (
                                <PortfolioCard
                                    data={item}
                                    key={item.uuid}
                                    onClickMediaCard={() => {
                                        onGalleryItemClick(item, galleryFilter);
                                    }}
                                />
                            ))}
                    </Carousel>
                </div>
            </div>
        )) ||
        (preview && (
            <div className={style.portfolioPlaceholder}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a id={SECTION_IDS.gallery} className={style.anchorGallery} />
                <div className={style.textheadingtestimonail}>{title}</div>
                <div className={style.galleryPlaceholderContainer}>
                    {(isLargeDesktop ? [1, 2, 3] : [1]).map(item => (
                        <div
                            key={item}
                            className={style.eventCardPlaceholder}
                            onClick={() => {
                                handlePreviewPopup();
                            }}
                        >
                            <div className={style.cardImage} />
                            <div className={style.caption}>
                                Gallery Caption {item}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )) ||
        null
    );
};
