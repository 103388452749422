/* eslint-disable react/require-default-props */
import { getItemCountFromResponsive, SECTION_IDS } from "@Utils";
import React from "react";
import { BiChevronLeft } from "@react-icons/all-files/bi/BiChevronLeft";
import { BiChevronRight } from "@react-icons/all-files/bi/BiChevronRight";
import Carousel from "react-multi-carousel";
import { ITemplate } from "@Templates/ITemplate";
import { TeamMemberCard } from "./components/TeamMemberCard";
import styles from "./TeamSection.module.scss";

const responsive = {
    largest: {
        breakpoint: { max: 4000, min: 1351 },
        items: 4,
    },
    large: {
        breakpoint: { max: 1350, min: 1001 },
        items: 3,
    },
    medium: {
        breakpoint: { max: 1000, min: 681 },
        items: 2,
    },
    small: {
        breakpoint: { max: 680, min: 0 },
        items: 1,
    },
};

export const TeamSection = ({
    hideComponent,
    teamMembersData,
    title,
}: ITemplate.ITeamSectionProps) => {
    if (hideComponent) return null;

    const ButtonGroup = ({
        next,
        previous,
    }: {
        next?: () => void;
        previous?: () => void;
    }) =>
        teamMembersData.length > 1 ? (
            <div className={`carousel-button-group ${styles.buttonGroup}`}>
                <div className={styles.arrowStyle} onClick={previous}>
                    <BiChevronLeft />
                </div>
                <div className={styles.arrowStyle} onClick={next}>
                    <BiChevronRight />
                </div>
            </div>
        ) : null;

    return (
        <div className={styles.teamSectionContainer}>
            <div id={SECTION_IDS.team} className={styles.teamAnchor} />
            <div className={styles.teamSectionSubContainer}>
                <div className={styles.title}>{title}</div>
                <Carousel
                    responsive={responsive}
                    infinite
                    renderButtonGroupOutside
                    arrows={false}
                    showDots={false}
                    {...(teamMembersData.length >
                        getItemCountFromResponsive(responsive) && {
                        autoPlay: true,
                        customButtonGroup: <ButtonGroup />,
                    })}
                >
                    {teamMembersData.map(item => (
                        <TeamMemberCard
                            key={item?.username}
                            data={item}
                            cardClick={() => {}}
                        />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};
