import { TeamMemberSocialIcons } from "@Modules/hostPage";
import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import styles from "./TeamMemberCard.module.scss";

export const TeamMemberCard = ({
    data,
}: ITemplate.ITeamMemberCardProps): JSX.Element | null => {
    return (
        <div
            className={styles.teamMemberCardContainer}
            style={{ backgroundImage: `url(${data?.displayImage})` }}
        >
            <TeamMemberSocialIcons socialInfo={data.socialInfo} />
            <div className={styles.detailsContainer}>
                <div className={styles.name}>
                    {data.memberName.slice(0, 21)}
                </div>
                <div className={styles.heading}>
                    {data.staffHeading.slice(0, 60)}
                </div>
                {data.staffDescription && (
                    <div className={styles.horizontalBar} />
                )}
                <div className={styles.description}>
                    {data.staffDescription.slice(0, 300)}
                </div>
            </div>
        </div>
    );
};
