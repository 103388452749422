import React from "react";
import { VideoPlayer } from "@Modules/common/";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import { IPortfolioCard } from "./PortfolioCard";
import style from "./PortfolioCard.module.scss";

export const PortfolioCard = (props: IPortfolioCard.IProps): JSX.Element => {
    const { data, onClickMediaCard } = props;

    return (
        <div className={style.portfolioCard}>
            <div
                role="presentation"
                className={style.portfolioImgContainer}
                onClick={onClickMediaCard}
            >
                {(data.type === "video" && (
                    <div className={style.video}>
                        <VideoPlayer
                            videoUrl={data.media}
                            controls
                            light
                            optimizeUsingThumbnail
                            height="100%"
                            width="100%"
                        />
                    </div>
                )) ||
                    (data.media && (
                        <ExlyImage
                            fetchWidth={500}
                            src={data.media}
                            loading="lazy"
                            alt="portfolio"
                        />
                    )) || <img src="" alt="" />}
                <div className={style.metaData}>{data.caption}</div>
            </div>
        </div>
    );
};
